
$(document).ready(function(){
    if (document.getElementsByClassName('js-categoryRoundCarousel')) {
        $('.categoryRoundCarousel').slick({
            dots: false,
            slidesToShow: 12,
            infinite: false,
            slidesToScroll: 1,
            arrows: false,

            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 10,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<span class="slick-prev"></span>',
                        nextArrow: '<span class="slick-next"></span>'
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<span class="slick-prev"></span>',
                        nextArrow: '<span class="slick-next"></span>'
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1,
                        arrows: true,
                        prevArrow: '<span class="slick-prev"></span>',
                        nextArrow: '<span class="slick-next"></span>'
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        autoplay: true,
                        dots: false,
                        arrows: false,
                        prevArrow: '',
                        nextArrow: ''
                    }
                }
            ]
        });

        $('.js-categoryRoundCarousel').removeClass('skeleton-loaderCarousel');
    }
});
